header{
  position: relative;
  margin-bottom: 150px;
  margin-top: 120px;  
}
.header_container h4{

}
.skill-section  {
  margin-top: 20px;
}

.text, .text-1{
   text-align: center;
  font-size: 14px;
  color: var(--color-light);
}
.header_container {
  text-align: center;
  min-height: 68vh;
  position: relative;
  margin-top: 8px;
}
.name {
  letter-spacing: 10px;
  font-size: 3.5em;
  text-shadow: 0px 0px 25px rgb(252, 248, 248);
  margin-top: -10px;
}
.cta {
  margin-top: 2rem;
  display: flex;
  gap: 1.2rem;
  justify-content: center;
  height: 30px;
  text-align: center;
}
span {
  margin: 0 3px;
}
.me {
  background: linear-gradient(var(--color-primary), transparent);
  width: auto;
  height: 30rem;
  left: 50%;
  transform: translateX(-50%);
  overflow: hidden;
  border-radius: 5rem 5rem 0rem 0rem;
  box-shadow: 0 0 65px #030303;
}

@media screen and (max-width: 1024px) {
  header {
    min-height: 100vh;
  }
}

@media screen and (max-width: 600px) {

  header {
    min-height: 100vh;
  }
  .scroll_down {
    display: none;
  }
  .text{
  text-align: center;
  font-size: 12px;

  color: var(--color-light);
}

  .name {
    letter-spacing: 2px;
    font-size: 2.1rem;
    text-align: center;
    display: contents;
  }

.skill-section  {
 margin-right: 25px;
 margin-left: 25px;
 
}

  .header_container {
 margin: 10px;
 width: 95%;
}
.me {
left: 47%;
}
}

@media screen and (max-width: 450px) {
  header {
    min-height: 100vh;
  }
  .scroll_down {
    display: none;
  }


  .text-1{
  text-align: center;
  font-size: 12px;

  color: var(--color-light);
}

.skill-section  {
 /* margin-right: 18px;
 margin-left: 20px; */

 
}


}

@media screen and (max-width: 400px) {
  header {
    min-height: 100vh;
  }
  .scroll_down {
    display: none;
  }
  .text{
  text-align: center;
  font-size: 11.5px;

  color: var(--color-light);
}

  .text-1{
  text-align: center;
  font-size: 10px;

  color: var(--color-light);
}

.skill-section  {
 margin-right: 25px;
 margin-left: 25px;

 
}


}

@media screen and (max-width: 376px) {
  header {
    min-height: 100vh;
  }
  .scroll_down {
    display: none;
  }
  .text{
  text-align: center;
  font-size: 11px;

  color: var(--color-light);
}

  .text-1{
  text-align: center;
  font-size: 10px;

  color: var(--color-light);
}
.skill-section  {
 margin-right: 20px;
 margin-left: 20px;
 padding: 5px;
 
}


}