.contact_us {
  background: linear-gradient(90deg, #1f1f38, #2c2c7c, #1f1f38);
  width: 100%;
  height: 100%;
  background-position: center;
  justify-content: center;


}
.contact_us h5 {
  text-align: center;
  padding: 5px;
  margin-top: 0;

  text-transform: uppercase;
  font-size: 16px;
  color: white;
  text-shadow: 0px 0px 15px rgb(252, 252, 252);
  font-family: "Montserrat", sans-serif;
}
.contact_us h4 {
  text-align: center;

  margin-top: 0;
  margin-bottom: 8px;
  color: white;
  text-shadow: 0px 0px 15px rgb(252, 252, 252);
  font-family: "Montserrat", sans-serif;
}
.contact_head {
  margin-top: 80px;
  margin-bottom: 10px;
  border-bottom: none;
  letter-spacing: 5px;
  font-size: 36px;
  text-align: center;
  font-family: "Montserrat", sans-serif;
  font-weight: bolder;
  text-shadow: 0px 0px 15px rgb(7, 7, 7);
}
.contact_container {
  display: grid;
  justify-items: center;
  height: 650px;
  width: 50%;
  margin: 20px auto;
}
.contact_box {
  height: 80%;
  width: 70%;
  background-image: linear-gradient(to right, rgb(216, 225, 227), rgb(90, 97, 131), rgb(216, 225, 227));
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.field {
  margin-bottom: 10px;
}

.field label {
  display: block;
  font-size: 12px;
  color: rgb(231, 228, 228);
  text-shadow: 0 0 10px rgb(107, 104, 104);
}

.field input {
  display: block;
  min-width: 10%;
  line-height: 1.5;
  font-size: 14px;
}

input[type="submit"] {
  display: block;
  padding: 6px;
  font-size: 14px;
  background-color: #323279;
  color: #fff;
  border: none;
  margin-top: 10px;
}
.errorMessage {
  color: red;
}
.required {
  font-size: 15px;
  color: white;
  text-align: center;
  text-shadow: 0 0 15px rgb(36, 35, 35);

  font-family: "Poppins", sans-serif;
}
.message {
  width: 100%;
}
#form {
  min-width: 40%;
}

@media screen and (max-width: 600px) {
  .contact_container {

  width: 100%;
  
 
}
}