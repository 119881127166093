.card {
  background: #2c2c8b;
  border-radius: 2rem 2rem 2rem 2rem;
  border: 3px solid var(--color-primary);
  padding: 0.5rem;
  transition: var(--transition);
  height: 685px;
  width: 365px;
 
}
.last_card {
  height: 90px;
}
.card:hover {
  background: transparent;
  border-color: var(--color-primary-variant);
  cursor: default;
  border: 4px solid var(--color-primary);
}

.card_photo h2 {
  text-align: center;
  margin-bottom: 10px;
  text-shadow: 0px 0px 25px rgb(252, 248, 248);
}
.card_photo p {
  text-align: center;
  margin-bottom: 10px;
  text-shadow: 0px 0px 25px rgb(252, 248, 248);
}
.try {
  font-style: normal;
  font-size: 13px;
}
.card_photo img {
  border-radius: 19px 19px 0 0;
  margin-bottom: 5px;
}
.card_photo img:hover {
  border-radius: 59px 59px 59px 59px;
  cursor: pointer;
}

.code_link {
  display: grid;
  grid-template-columns: 10% 90%;
  align-items: top;
  margin-left: 15px;
  color: darkgray;
  margin-top: 18px;
  margin-bottom: 18px;
  gap: 5px;
  font-size: 14px;
  font-style: italic;
}
.code_click {
  justify-self: left;
}
.code_click:visited,
.code_click:active,
.code_click:link {
  color: rgb(189, 189, 189);
}
.code_click:hover {
  color: rgb(248, 248, 248);
}
.code_link_icon {
  font-size: 24px;
  color: rgb(248, 248, 248);
  margin-top: 10px;
  width: fit-content;
  margin-top: -4.8px;
}
.call {
  margin-bottom: 15px;
}
.details_list {
  padding-right: 2.2px;
  margin-left: 2px;
}
.portfolio_container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: auto;
 gap: 3rem;
  justify-content: center;
  /* margin-top: 10px; */
  font-size: 12.2px;
  line-height: 1.3rem;
}
.service {
  background: #2c2c8b;

  border-radius: 2rem 2rem 2rem 2rem;
  border: 3px solid var(--color-primary);
  height: fit-content;
  padding: 0.5rem;
  transition: var(--transition);
}
.service_last {
  margin-bottom: 80px;
}

.service:hover {
  background: transparent;
  border-color: var(--color-primary-variant);
  cursor: default;
  border: 4px solid var(--color-primary);
}
.service_head {
  background-color: var(--color-primary);
  padding: 1rem;
  border-radius: 2rem 2rem 2rem 2rem;
  box-shadow: 0 rrem 1rem rgba(0, 0, 0, 0.1);
}

.service_head h3 {
  color: white;
  font-size: 1.2rem;
  text-align: center;
  font-weight: bold;
}
.service_list {
  padding: 1.5rem;
}
li {
  display: flex;
  gap: 1rem;
  margin-bottom: 0.8rem;
}
.portfolio_list-icon {
  color: rgb(177, 177, 246);
  margin-top: 4.5px;
}

.offer {
  margin-top: 95px;
  margin-bottom: 0;
  font-size: 16px;
}
.portfolio_title {
  font-size: 36px;
  margin-top: 0;
  margin-bottom: 0px;
  color: rgb(239, 234, 234);
  text-shadow: 0px 0px 35px rgb(248, 248, 248);
  text-transform: uppercase;
  letter-spacing: 2.8px;
}
/* Tablet Device Adjust */
@media screen and (max-width: 1024px) {
  .portfolio_container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 3rem;
    justify-content: center;
  }

  .offer {
    margin-top: 0px;
    margin-bottom: 0;
    font-size: 16px;
  }
  .card {
    background: #2c2c8b;
    border-radius: 2rem 2rem 2rem 2rem;
    border: 3px solid var(--color-primary);
    padding: 0.5rem;
    transition: var(--transition);
    height: 635px;
    width: 375px;
  }
}
/* Small Device Adjust */
@media screen and (max-width: 600px) {
  .portfolio_container {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1.5rem;
    justify-content: center;
  }

  .offer {
    margin-top: 0px;
    margin-bottom: 0;
    font-size: 16px;
  }
  .portfolio_title {
    font-size: 26px;
    margin-top: 0;
    margin-bottom: 0px;
    color: rgb(239, 234, 234);
    text-shadow: 0px 0px 35px rgb(248, 248, 248);
    text-transform: uppercase;
    letter-spacing: 2.8px;
  }
  .card {
    background: #2c2c8b;
    border-radius: 2rem 2rem 2rem 2rem;
    border: 3px solid var(--color-primary);
    padding: 0.5rem;
    transition: var(--transition);
    height: 635px;
    width: 375px;
  }
}
