section {
  margin-top: 80px;
}

.services_container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 3rem;
  justify-content: center;
  margin-top: 10px;
  font-size: 15px;
}
.service {
  background: #2c2c8b;
  border-radius: 2rem 2rem 2rem 2rem;
  border: 3px solid var(--color-primary);
  height: fit-content;
  padding: 0.5rem;
  transition: var(--transition);
}
.service_last{
margin-bottom: 80px;
}

.service:hover {
  background: transparent;
  border-color: var(--color-primary-variant);
  cursor: default;
  border: 4px solid var(--color-primary);
}
.service_head {
  background-color: var(--color-primary);
  padding: 1rem;
  border-radius: 2rem 2rem 2rem 2rem;
  box-shadow: 0 rrem 1rem rgba(0, 0, 0, 0.1);
}

.service_head h3 {
  color: white;
  font-size: 1.2rem;
  text-align: center;
  font-weight: bold;
}
.service_list {
  padding: 1.5rem;
}
li {
  display: flex;
  gap: 1rem;
  margin-bottom: 0.8rem;
}
.service_list-icon {
  color: rgb(177, 177, 246);
  margin-top: 4.5px;
}

.offer {
  margin-top: 95px;
  margin-bottom: 0;
  font-size: 16px;
}
.services_title {
  font-size: 36px;
  margin-top: 0;
  margin-bottom: 0px;
  color: rgb(239, 234, 234);
  text-shadow: 0px 0px 25px rgb(252, 248, 248);
  text-transform: uppercase;
  letter-spacing: 2px;
}
/* Tablet Device Adjust */
@media screen and (max-width: 1024px) {
  .services_container {
    display: grid;
    grid-template-columns: 1fr;
    gap: 2rem;
    justify-content: center;
  }
  .service {
    width: 80vw;
    padding: 0;
  }
  .offer {
    margin-top: 0px;
    margin-bottom: 0;
    font-size: 16px;
  }
}
/* Small Device Adjust */
@media screen and (max-width: 600px) {
  .services_container {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1.5rem;
    justify-content: center;
  }
  .service {
    height: auto;
    width: 360px;
    padding: 0;
  }
  .offer {
    margin-top: 0px;
    margin-bottom: 0;
    font-size: 16px;
  }
}
