@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200&family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;1,200;1,300;1,400;1,500;1,600&display=swap');
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@200&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  border: 0;
  outline: 0;
  text-decoration: none;
  list-style: none;
}

:root {
  --color-bg: #1f1f38;
  --color-bg-variant: #2c2c6c;
  --color-primary-variant: #4db5ff;
  --color-primary: rgb(77, 181, 255, 0.4);
  --color-white: #fff;
  --color-light: rgb(255, 255, 255, 0.6);

  --container-width-lg: 85%;
  --container-width-md: 65%;
  --container-width-sm: 40%;

  --transition: all 400ms ease;
}
html {
  scroll-behavior: smooth;
  overflow: scroll;
}
::-webkit-scrollbar {
  display: none;
}
body {
  font-family: "Poppins", sans-serif;
  background: linear-gradient(90deg, #1f1f38, #2c2c7c, #1f1f38);
  color: var(--color-white);
  line-height: 1.7;
}
/* General Styles */
.container {
  width: var(--container-width-lg);
  margin: 0px auto;
}
h1,
h2,
h3,
h4,
h4 {
  font-weight: 500;
}
h1 {
  font-size: 2.5rem;
}
section {
  margin-top: 4.5rem;
  height: 100vh;
}
section > h2,
section > h5 {
  text-align: center;
  color: var(--color-light);
}
section > h2 {
  margin-bottom: 3rem;
  color: var(--color-primary);
}
.text-light {
  color: var(--color-light);
}

a {
  color: var(--color-primary);
  transition: var(--transition);
}
a:hover {
  color: var(--color-white);
}
.btn {
  width: max-content;
  display: inline-block;
  color: var(--color-primary);
  padding: 0.2rem 1rem;
  border-radius: 0.4rem;
  cursor: pointer;
  border: 1px solid var(--color-primary);
  transition: var(--transition);
  font-size: 12px;

}
.btn:hover {
  background: var(--color-white);
  color: var(--color-bg);
  border-color: transparent;
}

.btn-primary {
  background: var(--color-primary);
  color: var(--color-bg);
}

img {
  display: block;
  width: 100%;
  object-fit: cover;
}
/* Tablet Device Adjust */
@media screen and (max-width: 1024px) {
  .container {
    width: var(--container-width-md);
  }
  .section {
    margin-top: 6rem;
  }
}
/* Small Device Adjust */
@media screen and (max-width: 600px) {
  .container {
    width: var(--container-width-md);
  }
  .section > h2 {
    margin-bottom: 2rem;
  }
  section > h5 {
    display: block;
  }
}
