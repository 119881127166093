.header_socials {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 1.4rem;
  font-size: 1.5rem;
  position: fixed;
  bottom: 0.4rem;
}
.social_head {
  color: var(--color-light);
  font-size: 14px;
  position: fixed;
  bottom: 2.9rem;
}

.header_socials::after {
  content: "";
  height: 1.4px;
  width: 2rem;
  background: var(--color-primary);
}
.header_socials::before {
  content: "";
  height: 1.4px;
  width: 2rem;
  background: var(--color-primary);
}
/* @media screen and (max-width: 1024px) {
  .header_socials {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1.4rem;
    font-size: 1.5rem;
    position: fixed;
   left: 1rem;
   bottom: 2rem;
   
  }

  .social_head{
 
  display: none;
  }
}

@media screen and (max-width: 400px) {
  .header_socials {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1.4rem;
    font-size: 1rem;
    position: fixed;
   left: .2rem;
   bottom: 2rem;
   
  }

  .social_head{
 
  display: none;
  }
} */
